<template>
  <svg
    xmlns="http://www.w3.org/20575DD/svg"
    width="22.549"
    height="15"
    viewBox="0 0 22.549 15"
  >
    <g
      id="Сгруппировать_3235"
      data-name="Сгруппировать 3235"
      transform="translate(-0.725 -4.5)"
    >
      <path
        id="Контур_933"
        data-name="Контур 933"
        d="M11.984,5.25c-3.653,0-7.4,2.115-10.351,6.344a.75.75,0,0,0-.013.833c2.267,3.548,5.964,6.323,10.364,6.323,4.352,0,8.125-2.783,10.4-6.34a.757.757,0,0,0,0-.819C20.1,8.076,16.3,5.25,11.984,5.25Z"
        fill="none"
        stroke="#0575DD"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        id="Контур_934"
        data-name="Контур 934"
        d="M12,15.75A3.75,3.75,0,1,0,8.25,12,3.75,3.75,0,0,0,12,15.75Z"
        fill="none"
        stroke="#0575DD"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </g>
  </svg>
</template>
