import { useCookie } from "#app";
import { useApi } from "./useApi.js";
import { useUserStore } from "../stores/useUserStore.js";
import { COOKIE_KEYS } from "../utils/index.js";
export function useAuth() {
  const { user, setUser } = useUserStore();
  const authToken = useCookie(COOKIE_KEYS.authToken);
  async function checkRouteProtection() {
    if (user) {
      return true;
    } else {
      await navigateTo({ path: "login" });
    }
  }
  function fetchUserData() {
    const { $get } = useApi();
    return new Promise((resolve, reject) => {
      $get("/api/v1/auth").then((res) => {
        setUser(res);
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    });
  }
  async function login(email, password) {
    const { $post } = useApi();
    return new Promise((resolve, reject) => {
      $post("/api/v1/auth/login", {
        email,
        password
      }).then((res) => {
        authToken.value = `Bearer ${res.accessToken}`;
        setTimeout(
          () => fetchUserData().then(() => {
            resolve(res);
          }).catch((err) => {
            console.error(err);
            reject(err);
          }),
          100
        );
      }).catch((err) => {
        console.error(err);
        reject(err);
      });
    });
  }
  return { fetchUserData, checkRouteProtection, login, user };
}
