<template>
  <svg
    xmlns="http://www.w3.org/20575DD/svg"
    width="23.5"
    height="20.518"
    viewBox="0 0 23.5 20.518"
  >
    <g
      id="Сгруппировать_3236"
      data-name="Сгруппировать 3236"
      transform="translate(-0.25 -2.814)"
    >
      <path
        id="Контур_935"
        data-name="Контур 935"
        d="M17.94,16.537A10.96,10.96,0,0,1,12,18.354C5,18.354,1,11.3,1,11.3A17.014,17.014,0,0,1,6.06,6.06"
        transform="translate(0 2.646)"
        fill="none"
        stroke="#0575DD"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        id="Контур_936"
        data-name="Контур 936"
        d="M14.12,14.12A3,3,0,1,1,9.88,9.88"
        transform="translate(0 1)"
        fill="none"
        stroke="#0575DD"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        id="Контур_937"
        data-name="Контур 937"
        d="M2.41,1.5,22.35,19.9"
        transform="translate(0 2.371)"
        fill="none"
        stroke="#0575DD"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        id="Контур_938"
        data-name="Контур 938"
        d="M9.9,4.223A9.785,9.785,0,0,1,12,4c7,0,11,7.433,11,7.433A17.18,17.18,0,0,1,20.84,14.4"
        transform="translate(0 2.91)"
        fill="none"
        stroke="#0575DD"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </g>
  </svg>
</template>
